(function ($) {
  $(function () {
    $('form.hood-search-form').submit(function (e) {
      e.preventDefault();
      let selectedVal = $('select[name=hood_to_go] option')
        .filter(':selected')
        .val();
      window.location.href = selectedVal;
    });
  });
})(jQuery);
