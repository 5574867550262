(function ($) {
  $(function () {
    // if (!$('section.blog').length) {
    //   return;
    // }

    let $articles = $('.articles .row');
    let $load_more = $('button.load-more');

    $load_more.click(function () {
      $load_more.addClass('active').attr('disabled', 'disabled');
      ThemeJS.Functions.send_ajax_request({
        data: {
          action: 'load_more_posts',
          query: $load_more.data(),
        },
        success: function (response) {
          console.log(response);
          response.articles.forEach(function (value, index) {
            let $article = $(value);
            $article.css('transition-delay', `${150 * index}ms`);
            $article.addClass('ajax-loaded');
            $articles.append($article);
          });
          requestAnimationFrame(function () {
            $articles.children().removeClass('ajax-loaded');
          });
          if (response.more) {
            $load_more.data('paged', parseInt($load_more.data('paged')) + 1);
            $load_more.removeClass('active').removeAttr('disabled');
          } else {
            $load_more.remove();
          }
        },
      });
    });
  });
})(jQuery);
