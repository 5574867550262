(function ($) {
  $(function () {
    let testimonialsSlider = $('.testimonials .slides');
    if (!testimonialsSlider.length) return;
    testimonialsSlider.slick({
      // centerMode: true,
      // centerPadding: '360px',
      slidesToShow: 3,
      // variableWidth: true,
      autoplay: true,
      slidesToScroll: 1,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      infinite: true,
      //   infinite: false,
      dots: true,
      arrows: true,
      draggable: true,
      nextArrow: $('.slider-controls .arrows .slick-next'),
      prevArrow: $('.slider-controls .arrows .slick-prev'),
      appendDots: $('.slider-controls .dots'),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    // relocate controls
    // let prevArr = document.querySelector('.slick-prev');
    // let nextArr = document.querySelector('.slick-next');
    // let dots = document.querySelector('.slick-dots');

    // if (nextArr) {
    //   document
    //     .querySelector('.slider-controls .arrows .next')
    //     .appendChild(nextArr);
    // }
    // if (prevArr) {
    //   document
    //     .querySelector('.slider-controls .arrows .prev')
    //     .appendChild(prevArr);
    // }
    // document.querySelector('.slider-controls .dots').appendChild(dots);

    let x = $('.testimonials .container-fluid.match');
    let y = $('.testimonials .to-match');
    if (x.height() > y.height()) y.css('height', x.height() + 'px');
    function resizedEnded() {
      x = $('.testimonials .container-fluid.match');
      y = $('.testimonials .to-match');
      if (x.height() > y.height()) y.css('height', x.height() + 'px');
    }
    let resizeId;
    $(window).resize(function () {
      clearTimeout(resizeId);
      resizeId = setTimeout(resizedEnded, 500);
    });

    // else if (y.height() > x.height()) x.css('height', y.height() + 'px');
  });
})(jQuery);
