let description_layout = document.querySelector('.description_layout');
if (description_layout) {
    document.querySelector('.description_layout .text-one').addEventListener('mouseenter', function () {
        document.querySelector('.description_layout .image-one').classList.add('hovered')
        // document.querySelector('.description_layout .image-one img').style.transform = "scale(1.5)";
    })
    document.querySelector('.description_layout .text-one').addEventListener('mouseleave', function () {
        document.querySelector('.description_layout .image-one').classList.remove('hovered')
    })

    document.querySelector('.description_layout .text-two').addEventListener('mouseenter', function () {
        document.querySelector('.description_layout .image-two').classList.add('hovered')
    })
    document.querySelector('.description_layout .text-two').addEventListener('mouseleave', function () {
        document.querySelector('.description_layout .image-two').classList.remove('hovered')
    })
//     console.log('jkjkj');
//     (function(jQuery) {
//     jQuery.fn.visible = function(partial) {
    
//         var jQueryt            = jQuery(this),
//             jQueryw            = jQuery(window),
//             viewTop       = jQueryw.scrollTop(),
//             viewBottom    = viewTop + jQueryw.height(),
//             _top          = jQueryt.offset().top,
//             _bottom       = _top + jQueryt.height(),
//             compareTop    = partial === true ? _bottom : _top,
//             compareBottom = partial === true ? _top : _bottom;
      
//       return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
  
//     };
      
//     })(jQuery);
    
//     jQuery(window).scroll(function(event) {
  
//         jQuery(".module").each(function(i, el) {
//           var el = jQuery(el);
//         //   if (el.visible(true)) {
//             el.addClass("come-in"); 
//         //   } 
//         });
        
//       });
}